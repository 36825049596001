import { Flex } from "@pancakeswap/uikit";
import Container from "components/Layout/Container";
import PageFullWidth from "components/Layout/PageFullWidth";
import { GetDataHome, UseCoreHome } from "state/home";
import Brand from "./Brand";
import Banner from "./components/Banner";
import Vouchers from "./components/Vouchers";
import { ColLeft } from "./components/styles";

const Home = () => {
    UseCoreHome()
    const dataHome = GetDataHome()
    const { ListBrand, ListCategory, isShowmore, category, price, ListProvinceId, isLoading } = dataHome
    return (
        <>
            <Vouchers
                ListBrand={ListBrand}
            />
            <Banner/>
            <PageFullWidth>
                <Container width="100%">
                    <Flex width="100%">
                        <ColLeft>
                            <Brand 
                                ListBrand={ListBrand}
                                ListCategory={ListCategory}
                                isShowmore={isShowmore}
                                category={category}
                                price={price}
                                ListProvinceId={ListProvinceId}
                                isLoading={isLoading}
                            />
                        </ColLeft>
                    </Flex>
                </Container>
            </PageFullWidth>
        </>
       
    )
}
export default Home