import { ArrowLeft, ArrowLeftCircle, Button, Flex, Heading, Input, InputGroup, SearchIcon, Text } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import Select, { OptionProps } from "components/Select/SelectProvince";
import SelectProvinceHome from "components/Select/SelectProvinceHome";
import { LightTooltip3 } from "components/ToolTip3";
import { MAXIMUM_SHOW_BRAND, MORE_BRAND } from "config";
import province from "config/constants/address/province.json";
import useTheme from "hooks/useTheme";
import Link from "next/link";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { fetchActiveCategory, fetchProvinceId } from "state/home/actions";
import { Brandtype, Categorytype } from "state/home/type";
import styled from "styled-components";
import { Navigation, Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Spin } from "antd";
import { GridTemplate } from "./styles";

interface Props {
    handleBack: (newValue:boolean) => void
    activeCategory: number|null
    ListBrand:Brandtype[],
    ListCategory:Categorytype[],
    ListProvinceId:string[],
    isLoading?:boolean,
}
const MoreBrand:React.FC<Props> = ({
    handleBack,
    activeCategory,
    ListBrand,
    ListCategory,
    ListProvinceId,
    isLoading
}) => {

    const dispatch = useDispatch<AppDispatch>()
    const { theme } = useTheme()
    const [ searchQuery, setSearchQuery ] = useState("")
    const [ selectProvince, setSelectProvince ] = useState("")
    const [ selectList, setSelectList ] = useState<Brandtype[]>(ListBrand)
    const sortSelectList1 = selectList?.filter((item) => item?.images !== '')
    const sortSelectList2 = selectList?.filter((item) => item?.images === '')
    const selectListConcat = sortSelectList1?.concat(sortSelectList2)

    const sliderSettings = {
        320: {
          slidesPerView: 3,
          spaceBetween: 5,
        },
        680: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
        1366: {
            slidesPerView: 5,
            spaceBetween: 5,
        },
    };
    const swiperRef = useRef<SwiperType>();
    
    const searchListBrand = useMemo(() =>{
        const listItem = [...ListBrand]
        if(searchQuery !== ''){
            return listItem?.filter((item) => item?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
        }
        return listItem
    },[ListBrand, searchQuery])

    const constSelectProvince = useMemo(() =>{
        const listItem = [...searchListBrand]
        if(selectProvince !== '0'){
            const listFilterProvince = ListProvinceId?.map((item) => {
                return listItem?.find((it) => it?.id?.toString() === item?.toString())
            })
            const filterProvince = listFilterProvince?.filter((item) => item !== undefined)
            setSelectList(filterProvince)

            return filterProvince
        }
        return listItem
    },[ListProvinceId, searchListBrand, selectProvince])
    
    useEffect(() => {
        const listItem = [...constSelectProvince]
        if(activeCategory){
            setSelectList(listItem?.filter((item) => item?.parent_cat_id?.toString() === activeCategory?.toString()))
        }
        if(activeCategory===0) setSelectList(listItem)
    },[ListBrand, activeCategory, constSelectProvince, searchListBrand])

    const handleSelectProvince = async (option: OptionProps) => {
        if(option === undefined){
            setSelectProvince('1'.toString())
        }
        setSelectProvince(option?.provinceId?.toString())
        await dispatch(fetchProvinceId({ cityId : option?.provinceId }))
    }
    
    const handleChangeCategory = (newValue:number) => {
        dispatch(fetchActiveCategory({category:newValue}))
    }

    const handleChangeBrand = (evt) => {
        const re = /[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$/
        if (evt.target.value === '' || re.test(evt.target.value)) {
            const values = evt.target.value
            const replace = values.replace(/[.*+?^${}()|[\]\\!@#%&_~]/g, "");
            setSearchQuery(replace)
        }
    }
    const allOption = [
        {
            provinceId: "0",
            provinceName: "Tất cả khu vực"
        },

    ]
    const concatProvince = allOption?.concat(province)

    const allOptionCategory = [
        {
            id: "0",
            images: "",
            title:"Tất cả"
        },
        {
            id: "1",
            images: "",
            title:"GiftCard"
        },
    ]
    const concatCategory = allOptionCategory?.concat(ListCategory)
    const [ itemOffset, setItemOffset ] = useState(1)

    const currentItem = useMemo(() => {
        if(selectListConcat?.length > MAXIMUM_SHOW_BRAND) {
            const limit = itemOffset*MAXIMUM_SHOW_BRAND
            const limit2 = MAXIMUM_SHOW_BRAND + ((itemOffset - 1) * MORE_BRAND)
            if(itemOffset !== 1){
                return [...selectListConcat.slice(0, limit2)]
            }
            return [...selectListConcat.slice(0, limit )]
        }
        return [...selectListConcat]
    },[itemOffset, selectListConcat])

    return (
        <Flex width="100%" flexDirection="column" style={{gap:"1rem"}} id="targetid">
            <Flex width="100%">
                <Flex alignItems="center" style={{gap:"12px", cursor:"pointer", width: 'fit-content'}} onClick={() => handleBack(false)}>
                    <ArrowLeftCircle width="32px" />
                    <Heading scale="lg">Quay lại</Heading>
                </Flex>
            </Flex>
            <Flex width="100%" mt="1rem">
                <Flex width="100%" justifyContent="space-between" alignItems="center" flexWrap="wrap" style={{gap:"1rem"}}>
                    <Heading scale="lg">Danh mục</Heading>
                    <Flex width="auto" flexWrap="wrap" style={{gap:"1rem"}}>
                        <CsFlex>
                            <InputGroup endIcon={<SearchIcon width="24px" />} scale="sm">
                                <Input type="text" value={searchQuery} placeholder="Tìm thương hiệu" onChange={(evt) => handleChangeBrand(evt)} />
                            </InputGroup>
                        </CsFlex>
                        <CsFlex>
                            <SelectProvinceHome
                                options={concatProvince}
                                onOptionChange={handleSelectProvince}
                                placeHolderText="Khu vực có cửa hàng"
                                isDefaultProvince={!false}
                                isLoading={false}
                                selectedOption={province?.find((item) => item?.provinceId?.toString() === selectProvince?.toString())}
                                />
                        </CsFlex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex width="100%" style={{gap:"12px"}}>
                <Flex width="100%" alignItems="center">
                    <Flex onClick={() => swiperRef.current?.slidePrev()} style={{cursor:"pointer", transform:"rotate(180deg)"}}>
                        <ArrowLeftCircle width="32px" style={{ transform:"rotate(180deg)"}} />
                    </Flex>
                    <Swiper
                        slidesPerView={5}
                        pagination={{ clickable: true }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        spaceBetween={0}
                        breakpoints={sliderSettings}
                        modules={[Navigation]}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                    >
                        { concatCategory?.map((dt) => {
                                return (
                                    <SwiperSlide>
                                        <CsButton
                                            variant={new BigNumber(activeCategory).isEqualTo(dt?.id)  ? "primary" :"text"}
                                            onClick={() => handleChangeCategory(+dt?.id)}
                                        >
                                            {dt?.title}
                                        </CsButton>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    
                    <Flex onClick={() => swiperRef.current?.slideNext()}>
                        <ArrowLeftCircle width="32px" style={{cursor:"pointer", transform:"rotate(180deg)"}} />
                    </Flex>
                </Flex>
            </Flex>
            <Flex width="100%">
                <Flex alignItems="center">
                    <Text>Đã tìm thấy</Text>&nbsp;
                    <Text bold color="warning43">{selectList?.length} thương hiệu</Text>
                </Flex>
            </Flex>
                {
                    isLoading ?
                        <Flex mt="2rem" width="100%" height="100%" justifyContent="center" flexDirection="column" alignItems="center">
                            <Spin size="small"/>
                        </Flex>
                    : 
                    <>
                        <GridTemplate>
                            { currentItem?.map((dt) => {
                                return (
                                    <Link href={dt?.isNewBrand ? `/brand/${dt?.id}?newbrand=true` : `/brand/${dt?.id}` }>
                                        {
                                            dt?.images !== '' ?
                                            <Flex width="100%" height="auto" borderRadius="10px" border={`1px solid ${theme.colors.silver}`} style={{cursor:"pointer"}}>
                                                <img src={dt?.images} alt={dt?.title} style={{ borderRadius: '9px' }}/>
                                            </Flex>
                                            :
                                            <LightTooltip3 title={dt?.title}>
                                                <Flex style={{ cursor: 'pointer' }}>
                                                    <img src="/images/logo/noLogo.png" alt={dt?.title} />
                                                </Flex>
                                            </LightTooltip3>
                                        }
                                    </Link>
                                    )
                                })
                            }
                        </GridTemplate>
                    </>
                }
                
            <Flex width="100%" justifyContent="center" alignItems="center" mt="1rem" mb="1rem">
                { selectList?.length - (currentItem?.length) > 0 &&
                    <>
                        <Button
                            variant="secondary"
                            endIcon={<ArrowLeft style={{transform:"rotate(90deg)"}}/>}
                            onClick={() => (setItemOffset(itemOffset+1))}
                        >
                            + {selectList?.length - currentItem?.length } thương hiệu
                        </Button>
                    </>
                }
            </Flex>
        </Flex>
    )
}

export default MoreBrand

const CsFlex = styled(Flex)`
    width: 250px;
    @media screen and (max-width:1280px) and (min-width: 1001px) {
        width: 220px;
    }
    @media screen and (max-width:600px) {
        width: 100%;
    }
`

const CsButton = styled(Button)`
    width: 140px;
    padding: 0px 5px;
    font-size: 12px;
    border-radius: 40px;
    @media screen and (max-width:600px){
        width: 95px;
    }
`