import { Button, Flex, Text } from "@pancakeswap/uikit";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { fetchStatusScreen } from "state/giftCards/actions";
import { fetchStatusShowmore } from "state/home/actions";
import { Brandtype } from "state/home/type";
import styled from "styled-components";
import { useRouter } from "next/router";
import { listBank } from "../config";
import { CardImg, GirdImage } from "./styles";

interface Props {
    ListBrand:Brandtype[],
}

const Vouchers:React.FC<Props> = ({ ListBrand }) => {
    const router = useRouter()
    const dispatch = useDispatch<AppDispatch>()
    const handleShowMore = (newValue:boolean) => {
        dispatch(fetchStatusShowmore({isShowmore:newValue}))
    }
    const filernoneImg = ListBrand?.filter((item) => item?.images !== '')
    const sliceListBrand = filernoneImg?.slice(0,15)
    console.log("asdasdadasd", sliceListBrand)
    const countListBrandMore = ListBrand?.length - sliceListBrand?.length

    const handleClickSwap = async () => {
        await router.push('/order')
        await dispatch(fetchStatusScreen({isActiveScreen:0}))
    }
    const handleClickShowMore = async () => {
        await handleShowMore(!false)
        await document.getElementById('targetid').scrollIntoView({behavior: 'smooth'});
    }
    return (
        <Container>
            <CsFlex>
                <Flex width="100%" flexDirection="column" style={{gap:"14px"}}>
                    <Flex width="100%" justifyContent="center">
                        {/* <MenuIconDark width="180px" /> */}
                        <img src="/images/logoBlack.png?version=1.1" alt="" width="180px" />
                    </Flex>
                    <Flex width="100%" flexDirection="column" style={{gap:"14px"}}>
                        <Text textTransform="uppercase" bold color="black">Voucher trên nền tảng blockchain</Text>
                        <Flex width="100%">
                            <img
                                src="/images/giftcards/vouchers.png"
                                alt=""
                                style={{width:"100%",height:"auto"}}
                            />
                        </Flex>
                    </Flex>
                    <Text color="black" bold>HÌNH THỨC ĐỔI ĐIỂM</Text>
                    <GirdImage>
                        {listBank?.map((dt) => {
                            return (
                                <CardImg isActive={dt?.isActive} isDisable={dt?.isDisable}>
                                    <img src={dt?.image} alt=""/>
                                </CardImg>
                            )
                        })

                        }
                    </GirdImage>
                    <Text color="black" bold>VOUCHER CÓ SẴN TẠI</Text>
                    <GirdImage>
                        {sliceListBrand?.map((dt) => {
                            return (
                                <Link href={dt?.isNewBrand ? `/brand/${dt?.id}?newbrand=true` : `/brand/${dt?.id}`}>
                                    {
                                        dt?.images !== '' ?
                                            <CardImg style={{cursor:"pointer"}}>
                                                <img src={dt?.images} alt="" style={{width:"auto", height:"100%"}}/>
                                            </CardImg>
                                        :
                                            <Flex style={{ cursor: 'pointer' }} width="26px" height="26px">
                                                <img src="/images/logo/noLogo.png" alt={dt?.title} />
                                            </Flex>
                                    }
                                </Link>
                            )
                        })

                        }
                    </GirdImage>
                        <Flex onClick={handleClickShowMore} style={{cursor:"pointer", display: 'flex', justifyContent: 'center'}}>
                            <Text color="primary" style={{textDecoration:"underline"}}>+ 300 thương hiệu toàn quốc</Text>
                        </Flex>
                    <Flex width="100%" justifyContent="center">
                        <Button 
                            style={{borderRadius:"40px"}}
                            onClick={handleClickSwap}
                            // onClick={() => dispatch(fetchStatusScreen({isActiveScreen:0})) }
                        >
                            {/* <Link href="/order"> */}
                                ĐỔI THẺ QUÀ TẶNG NGAY
                            {/* </Link> */}
                        </Button>
                    </Flex>
                </Flex>
            </CsFlex>
        </Container>
    )
}

export default Vouchers

const Container = styled.div`
    width: 440px;
    height: 0px;
    position: -webkit-sticky;
    position: sticky;
    top: 110px;
    left: 62.5%;
    @media screen and (max-width:768px) {
        position: fixed;
        width: 100%;
        height: auto;
        top: 370px;
        left: 10%;
    }
    @media screen and (max-width:1280px) and (min-width:1001px) {
        width: 370px;
    }
    @media screen and (max-width:768px) and (min-width:601px) {
        width: 620px;
    }
    @media screen and (max-width:600px) {
        width: 100%;
        position: fixed;
        height: auto;
        top: 320px;
        left: 0px;
        padding-left: 10px;
        padding-right: 10px;
    }
`
const CsFlex = styled(Flex)`
    width: 100%;
    height: auto; 
    border-radius: 28px;
    padding: 1.5rem 2rem;
    background: ${({ theme }) => theme.colors.background};
    box-shadow: ${({ theme }) => theme.colors.boxShadow};
    z-index: 10;
`