import { useState, useEffect, useRef, useMemo } from 'react'
import styled, { css } from 'styled-components'
import useTheme from 'hooks/useTheme'
import { BoxProps, IconSort, Text, Flex, InputGroup, SearchIcon, Input, AutoRenewIcon } from '@pancakeswap/uikit'
import { latinise, toLowerCaseNonAccentVietnamese } from 'utils/latinise'
import province from "config/constants/address/province.json";
import { fetchListProvince, fetchListProvinceByBrandId, fetchProvinceId } from 'state/giftCards/actions'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { Brandtype } from 'state/giftCards/type'

const DropDownHeader = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    transition: border-radius 0.15s;
    color: #fdb533;
    > svg {
        fill: #fff !important;
    }

    @media screen and (max-width: 600px) {
        padding: 0 10px;
        width: 80%;
        font-size: 12px;
    }
`
const CsText = styled(Text)`
    font-size: 16px;
    @media screen and (max-width: 600px) {
        font-size: 15px;
    }
`

const DropDownListContainer = styled.div`
    width: 180px;
    height: 100%;
    max-height: 250px;
    padding: 0px;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    transition: transform 0.15s, opacity 0.15s;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    box-sizing: border-box;
    box-shadow: 0px 8px 28px 0px rgba(66, 66, 66, 0.10);
`

const DropDownContainer = styled.div<{ isOpen: boolean; width: number; height: number }>`
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 48px;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
    ${({ theme }) => theme.mediaQueries.sm} {
        min-width: 100px;
    }
    ${(props) =>
        props.isOpen &&
        css`
            ${DropDownListContainer} {
                height: auto;
                transform: scaleY(1);
                opacity: 1;
                border-top-width: 0;
                width: 100%;
                background: ${({ theme }) => theme.colors.background};
                top: 49px;
                border-radius: 12px;
                z-index: 20;
            }
        `}
`

const DropDownList = styled.ul<{isDisabled: boolean}>`
    margin: 0;
    box-sizing: border-box;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    /* display: ${({ isDisabled }) => isDisabled ? 'none' : 'block'}; */
`

const ListItem = styled.li`
    list-style: none;
    padding: 8px 28px;
    border-radius: 0px;
    :hover {
        background: ${({ theme }) => theme.colors.backgroundAlt2};
    }
`

export interface SelectProps extends BoxProps {
    options: OptionProps[] | any,
    onOptionChange?: (option: OptionProps) => void
    placeHolderText?: string,
    isDefaultProvince:boolean,
    isLoading:boolean,
    selectedOption:OptionProps|any,
    disabled?:boolean
}

export interface OptionProps {
    provinceName: string
    provinceId: string,
    amount?:number,
}

const SelectProvinceHome: React.FunctionComponent<React.PropsWithChildren<SelectProps>> = ({
    options,
    onOptionChange,
    placeHolderText,
    isDefaultProvince,
    isLoading,
    selectedOption,
    disabled,
    ...props
}) => {
    const dispatch = useDispatch<AppDispatch>()
    const containerRef = useRef(null)
    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [optionSelected, setOptionSelected] = useState(false)
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
    const [ filterData, setFillterData ] = useState([...options])
    const [ query, setQuery ] = useState("")
    const { theme } = useTheme() 

    const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
        if (options.length > 0 && !disabled) {
            setIsOpen(!isOpen)
        }
        event.stopPropagation()
    }

    const handleClicked = (data: OptionProps) => async () => {
        setIsOpen(false)
        setOptionSelected(true)
        setQuery("")
        if (onOptionChange) {
            onOptionChange(data)
        }
    }
    
    const handleClickOutside = () => {
        if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
          setIsOpen(false)
        }
      }

    useEffect(() => {
        setContainerSize({
            width: dropdownRef.current.offsetWidth, // Consider border
            height: dropdownRef.current.offsetHeight,
        })

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const handleChangeQuery = (event) => {
        console.log('event',event)
        const re = /[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$/
        if (event.target.value === '' || re.test(event.target.value)) {
            const values = event.target.value
            const replace = values.replace(/[.*+?^${}()|[\]\\!@#%&_~]/g, "");
            setQuery(replace)
        }
    }

    useEffect(() => {
        const lowercaseQuery = toLowerCaseNonAccentVietnamese(query.toLowerCase())
        if (query?.length > 0 ) {
            setFillterData([...options.filter((it) => toLowerCaseNonAccentVietnamese(it?.provinceName.toLowerCase()).includes(lowercaseQuery))])
        } else {
            setFillterData([...options])
        }
    },[options, query])

    useEffect(() => {
        if(isDefaultProvince && !optionSelected ){
            onOptionChange({provinceId: '0', provinceName: 'Tất cả'})
        }
    },[isDefaultProvince, onOptionChange, optionSelected])

    return (
        <DropDownContainer isOpen={isOpen} {...props} ref={containerRef} {...containerSize}>
            {containerSize.width !== 0 && (
                <DropDownHeader onClick={ toggling }>
                        <CsText color={(!optionSelected && placeHolderText) ? 'text' : 'text'}>
                            {
                                selectedOption === undefined ? 'Tất cả khu vực' :
                                <>
                                    {
                                        isLoading ? <Text>Loading...</Text> :
                                        <>
                                            {
                                                options?.length === 0 ? 'Không có tỉnh thành' :
                                                <>
                                                    {!optionSelected && placeHolderText ? placeHolderText : selectedOption?.provinceName}
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }

                        </CsText>
                </DropDownHeader>
            )}
            <Flex position="absolute" right="16px" top="13px">
                <IconSort color="black" onClick={toggling} style={{transform: isOpen && 'rotate(180deg)'}}  />
            </Flex>
            <DropDownListContainer>
                <Flex width="100%" height="auto" padding="12px 12px 0px 12px">
                    <InputGroup startIcon={<SearchIcon/>} mb="2px" scale="sm" isHiddenBorder>
                        <Input type="text" placeholder='Tìm kiếm' value={query} onChange={(evt) => handleChangeQuery(evt)} />
                    </InputGroup>
                </Flex>
                <Flex width="100%" borderBottom={`1px solid ${theme.colors.backgroundTab}`}/>
                <DropDownList ref={dropdownRef} isDisabled={isLoading}>
                    { filterData.map((option) =>
                        placeHolderText || option?.provinceId !== selectedOption?.provinceId ? (
                            <ListItem onClick={handleClicked(option)} key={option?.provinceName}>
                                <CsText color={option?.provinceId === selectedOption?.provinceId ? "gold" : "text"}>{option?.provinceName} {option?.amount && `(${option?.amount})`} </CsText>
                            </ListItem>
                        ) : null,
                    )}
                </DropDownList>
            </DropDownListContainer>
        </DropDownContainer>
    )
}

export default SelectProvinceHome