import { Flex, Heading, Text } from "@pancakeswap/uikit"
import styled from "styled-components"

export const GridTemplate = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
    @media screen and (max-width:600px) {
        grid-template-columns: repeat(4, 1fr);
    }
`
export const ContainerPrice = styled(Flex)`
    width: 100%;
    height: 92px;
    border-radius: 12px;
    align-items: center;
    padding: 0rem 8px;
    background:${({ theme }) => theme.colors.gradientBubblegum};
    justify-content: space-around;
    @media screen and (max-width:600px) {
        flex-wrap:wrap;
        justify-content:space-between;
        gap: 1.5rem;
        height: auto;
        padding: 1rem;
        padding-bottom: 1.5rem;
    }
`
export const Card = styled(Flex)`
    width: 137.708px;
    height: 52px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    ${Text}{
        font-size: 13px;
    }
    cursor: pointer;
    @media screen and (max-width:600px) {
        width: 45%;
    }
`
export const CsTagPrice = styled(Flex)`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
`
export const CsHeading = styled(Heading)`
    font-size: 40px;
    @media screen and (max-width:600px) {
        font-size: 22px;
    }
`