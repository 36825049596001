import { Box, Button, Flex, Image, Text } from "@pancakeswap/uikit";
import Container from "components/Layout/Container";
import Link from "next/link"
import { ColLeft, ContainerBanner, CsHeading, RowForBanner } from "./styles";

const Banner = () => {
    return (
        <ContainerBanner>
            <Container width="100%" height="100%">
                <RowForBanner>
                    <ColLeft>
                        <Flex width="100%" flexDirection="column" style={{gap:'1rem'}}>
                            <Flex width="100%">
                                <CsHeading color="white">300+ thương hiệu nổi tiếng trên toàn quốc</CsHeading>
                            </Flex>
                            <Box width="100%" maxWidth="600px">
                                <Flex width="100%">
                                    <Text color="white" fontSize={['12px', '14px' , '16px']}>Nền tảng đầu tiên cho phép sử dụng <span style={{color: 'white', whiteSpace: 'nowrap', fontWeight: '600'}}>điểm thưởng</span> để đổi voucher</Text>
                                </Flex>
                                <Flex width="100%" mt="12px">
                                    <Image
                                        src="/images/giftcards/chains.png?version=1.2"
                                        width={300}
                                        height={52}
                                    />
                                </Flex>
                            </Box>
                        </Flex>
                    </ColLeft>
                </RowForBanner>
            </Container>
        </ContainerBanner>
    )
}

export default Banner