import { ArrowLeft, Button, Flex, Heading, TagPriceActive, TagPriceDefault, Text } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { LightTooltip3 } from "components/ToolTip3";
import useTheme from "hooks/useTheme";
import Link from "next/link";
import React from "react";
import { Brandtype } from "state/home/type";
import { listPrice } from "../config";
import { Card, ContainerPrice, CsTagPrice, GridTemplate } from "./styles";

interface Props {
    activePrice:number,
    handleChangePrice: (newPrice:number) => void
    handleShowMore:(newValue:boolean) => void,
    ListBrand:Brandtype[],
}
const LessBrand:React.FC<Props> = ({
    activePrice,
    handleChangePrice,
    handleShowMore,
    ListBrand
}) => {
    const { theme } = useTheme()
    const filterHasImg = ListBrand?.filter((item) => item?.images !== '')
    const countListBrandMore = ListBrand?.length - filterHasImg?.length
    return (
        <Flex width="100%" flexDirection="column" style={{gap:"1rem"}}>
            {/* <Flex width="100%" mb="1rem">
                    <Heading scale="xl">Các mức đổi điểm</Heading>
            </Flex>
            <Flex width="100%" mt="2rem">
                    <ContainerPrice>
                        {listPrice?.map((dt) => {
                            return (
                                <Card >
                                    <Text color="white" style={{zIndex:10}}>{Number(dt?.value).toLocaleString("en",{minimumFractionDigits: 0, maximumFractionDigits: 0})}{dt?.unit}</Text>
                                    <CsTagPrice>
                                        <TagPriceDefault width="100%"/>
                                    </CsTagPrice>
                                </Card>
                            )
                        })

                        }
                        
                    </ContainerPrice>
                </Flex>     */}
                <Flex width="100%" mb="1rem" mt="2rem">
                    <Heading scale="xl">Thương hiệu nổi bật</Heading>
                </Flex>
                <GridTemplate>
                    { filterHasImg?.slice(0,18)?.map((dt) => {
                        return (
                                <Link href={dt?.isNewBrand ? `/brand/${dt?.id}?newbrand=true` : `/brand/${dt?.id}` }>
                                    {
                                        dt?.images !== '' ?
                                        <Flex width="100%" height="auto" borderRadius="10px" border={`1px solid ${theme.colors.silver}`} style={{cursor:"pointer"}}>
                                            <img src={dt?.images} alt={dt?.title} style={{ borderRadius: '9px' }}/>
                                        </Flex>
                                        :
                                        <>
                                            <LightTooltip3 title={dt?.title}>
                                                <Flex style={{ cursor: 'pointer' }}>
                                                    <>
                                                    <img src="/images/logo/noLogo.png" alt={dt?.title} />
                                                    </>
                                                </Flex>
                                            </LightTooltip3>
                                        </>
                                    }
                                </Link>
                            )
                        })
                    }
                </GridTemplate>
                <Flex width="100%" mt="1rem" mb="3rem" justifyContent="center">
                    <Button
                        variant="secondary"
                        endIcon={<ArrowLeft/>}
                        onClick={() => handleShowMore(!false)}
                    >
                        + 300 thương hiệu
                    </Button>
                </Flex>    
        </Flex>
    )
}
export default LessBrand

