import { Flex } from "@pancakeswap/uikit";
import useTheme from "hooks/useTheme";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { GetDataHome } from "state/home";
import { fetchActiveCategory, fetchActivePrice, fetchStatusShowmore } from "state/home/actions";
import { Brandtype, Categorytype } from "state/home/type";
import LessBrand from "./components/LessBrand";
import MoreBrand from "./components/MoreBrand";

interface Props {
    ListBrand:Brandtype[],
    ListCategory:Categorytype[],
    isShowmore:boolean,
    category:number,
    price:number,
    ListProvinceId:string[],
    isLoading?:boolean
}

const Brand:React.FC<Props> = ({ ListBrand, ListCategory, isShowmore, category, price, ListProvinceId, isLoading }) => {    
    const dispatch = useDispatch<AppDispatch>()

    const handleSelectPrice = (value:number) => {
        dispatch(fetchActivePrice({price:value}))
    }
    const handleShowMore = (newValue:boolean) => {
        dispatch(fetchStatusShowmore({isShowmore:newValue}))
    }

    return (
        <Flex width="100%" height="auto">
            {isShowmore ?
                <MoreBrand
                    handleBack={(newValue) => handleShowMore(newValue)}
                    activeCategory={category}
                    ListBrand={ListBrand}
                    ListCategory={ListCategory}
                    ListProvinceId={ListProvinceId}
                    isLoading={isLoading}
                />
            :
                <LessBrand
                    activePrice={price}
                    handleChangePrice={handleSelectPrice}
                    handleShowMore={(newValue) => handleShowMore(newValue)}
                    ListBrand={ListBrand}
                />
            }
        </Flex>
    )
}

export default Brand

